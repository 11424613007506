"use client"

import { Fragment } from "react"
import Head from "next/head"

import { notFoundData } from "~/config/not-found"
import { useLocale } from "~/lib/i18n/hooks/useLocale"
import { handleZodSafeParse } from "~/lib/zod/utils/handle-zod-safe-parse"
import useLang from "~/hooks/useLang"
import { heroMainSliceSchema, serializeHeroMainSliceSync } from "~/components/slices/Heroes/HeroMain/_data/serializer"
import { HeroMainClient } from "~/components/slices/Heroes/HeroMain/index.client"

export default function NotFoundPage() {
  const locale = useLocale()
  const lang = useLang()
  const heroMainFromLang = notFoundData?.[lang]?.[0]

  const parsedHeroMain = handleZodSafeParse(heroMainSliceSchema, heroMainFromLang, {
    errorContext: "serializeNotFoundHeroMainSlice",
  })

  const heroMain = serializeHeroMainSliceSync(parsedHeroMain, locale)

  return (
    <Fragment>
      <Head>
        <meta key="robots" name="robots" content="noindex,nofollow" />
      </Head>
      {heroMain && <HeroMainClient {...heroMain} isFirstSlice={true} />}
    </Fragment>
  )
}
