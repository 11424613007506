"use client"

import { clsx } from "clsx"

import type { InferReturn } from "~/@types/generics"
import { Asset } from "~/components/ui/Asset"
import { Image } from "~/components/ui/Image"
import RichText from "~/components/ui/RichText"
import RoundedCta from "~/components/ui/RoundedCta"
import WrapperWithLink from "~/components/abstracts/WrapperWithLink"
import type serializeHeroMainSlice from "~/components/slices/Heroes/HeroMain/_data/serializer"

import { text, type TextVariants } from "~/styles/variants"

import * as css from "./styles.css"

type HeroMainClientProps = InferReturn<typeof serializeHeroMainSlice>

const IMAGE_SIZES = [{ ratio: 1 }]

function HeroMainClient({ layout, content, pngOverlay, isFirstSlice }: HeroMainClientProps) {
  const suptitleAndTitleTextColor: NonNullable<TextVariants>["color"] =
    content.textTheme === "dark" ? "blue-deep" : "grey-bg-page"

  const paraphraphTextColor: NonNullable<TextVariants>["color"] =
    content.textTheme === "dark" ? "blue-light-txt" : "grey-bg-page"

  return (
    <WrapperWithLink
      link={content.cta}
      className={clsx(css.HeroMain, css.layouts[layout])}
      data-comp="Slices/Heroes/HeroMain"
    >
      <Asset
        className={clsx(css.image)}
        {...content.asset}
        sizesFromBreakpoints={IMAGE_SIZES}
        ratio={{ tablet: "9/5", mobile: "5/8" }}
        objectFit="cover"
        asPlaceholder
        priority={isFirstSlice}
        videos={{
          ...content.videos,
          videoClassname: css.video,
          videoRatio: { tablet: "9/5", mobile: "5/8" },
        }}
      />
      <div className={clsx(css.texts)}>
        {pngOverlay && (
          <Image
            className={css.overlay}
            sizesFromBreakpoints={IMAGE_SIZES}
            asPlaceholder={false}
            noBackground
            {...pngOverlay}
          />
        )}
        {content.suptitle && (
          <RichText
            className={clsx(
              text({
                design: "riposte-13",
                color: suptitleAndTitleTextColor,
                transform: "uppercase",
                weight: "medium",
                letterSpacing: 1,
              })
            )}
            render={content.suptitle}
          />
        )}
        <RichText
          className={clsx(
            text({
              design: "spezia-32-80",
              color: suptitleAndTitleTextColor,
              transform: "uppercase",
              letterSpacing: 0.3,
            }),
            css.title
          )}
          render={content.title}
        />
        {content.subtitle && (
          <RichText
            className={clsx(
              text({
                design: "riposte-13",
                color: paraphraphTextColor,
                transform: "uppercase",
                weight: "medium",
                letterSpacing: 1,
              })
            )}
            render={content.subtitle}
          />
        )}
        {content.cta && <RoundedCta asDiv className={css.cta} {...content.cta} />}
      </div>
    </WrapperWithLink>
  )
}

export { HeroMainClient, type HeroMainClientProps }
