import { z } from "zod"

import type { Nullish } from "~/@types/generics"
import { optionalTupleSchema } from "~/lib/zod/utils/optional-tuple-schema"
import { optionalImageSchema } from "~/components/ui/Image/_data/schema"
import serializeImage from "~/components/ui/Image/_data/serializer"
import { defaultSliceSchema } from "~/components/slices/constants"
import { heroCardSharedContentSchema } from "~/components/slices/Heroes/_data/schema"
import serializeHeroCardSharedContent, {
  serializeHeroCardSharedContentSync,
} from "~/components/slices/Heroes/_data/serializer"

export const heroMainSliceSchema = defaultSliceSchema("hero_main").extend({
  layout: z
    .string()
    .optional()
    .transform((value) => (Boolean(value) ? value : undefined))
    .pipe(z.enum(["top", "center", "bottom"]).optional().default("center")),
  content: z.tuple([heroCardSharedContentSchema]),
  png_image: optionalTupleSchema(optionalImageSchema).nullish(),
})

type HeroMainSliceSchema = z.infer<typeof heroMainSliceSchema>

export default async function serializeHeroMainSlice(slice: HeroMainSliceSchema, locale: Nullish<string>) {
  return {
    layout: slice.layout,
    content: {
      ...(await serializeHeroCardSharedContent(slice.content[0], locale)),
      textTheme: slice.content[0].text_color,
    },
    pngOverlay: slice?.png_image?.[0] ? serializeImage(slice.png_image[0].image) : null,
    isFirstSlice: slice.isFirstSlice,
  }
}

export function serializeHeroMainSliceSync(slice: Nullish<HeroMainSliceSchema>, locale: Nullish<string>) {
  if (!slice) {
    return null
  }

  return {
    layout: slice.layout,
    content: {
      ...serializeHeroCardSharedContentSync(slice.content[0], locale),
      textTheme: slice.content[0].text_color,
    },
    pngOverlay: slice?.png_image?.[0] ? serializeImage(slice.png_image[0].image) : null,
  }
}
